export const work = [
  {
    id: 11,
    work_type: "งานประจำ",
    frequency: "",
    truck_type: "รถเทรเลอร์พื้นเรียบ ",
    sub_truck_type: "/ Side curtain",
    product: "วัสดุก่อสร้าง",
    weight: "",
    origin: "รังสิต ปทุมธานี",
    destination: "เมือง นครศรีธรรมราช",
    distance: "",
    price: "",
    detail: {
      work_type: "งานประจำ",
      product: "วัสดุก่อสร้าง",
      distance: "",
      work: [""],
      truck: [""],
      driver: [""],
      truck_type: "รถเทรเลอร์พื้นเรียบ / Side curtain",
      origin: "รังสิต ปทุมธานี",
      destination: " เมือง นครศรีธรรมราช",
      frequency: "",
      finance: "",
      remark: "",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 10,
    work_type: "งานประจำ",
    frequency: "",
    truck_type: "รถเทรลเลอร์พื้นเรียบ",
    sub_truck_type: "สินค้าขวดเปล่า",
    product: "สินค้าขวดเปล่า",
    weight: "",
    origin: ["นครปฐม", "สมุทราสาคร", "สมุทรสาคร", "บุรีรัมย์", "อุบลราชธานี"],
    destination: [
      "สมุทรสาคร",
      "บางบาล อยุธยา",
      "คลองขลุง กำแพงเพชร",
      "บางบาล อยุธยา",
      "บางบาล อยุธยา",
    ],
    distance: "",
    price: "",
    detail: {
      work_type: "งานประจำ",
      product: "สินค้าขวดเปล่า",
      distance: "",
      work: [""],
      truck: [""],
      driver: [""],
      truck_type: "รถเทรลเลอร์พื้นเรียบ",
      origin: ["นครปฐม", "สมุทราสาคร", "สมุทรสาคร", "บุรีรัมย์", "อุบลราชธานี"],
      destination: [
        "สมุทรสาคร",
        "บางบาล อยุธยา",
        "คลองขลุง กำแพงเพชร",
        "บางบาล อยุธยา",
        "บางบาล อยุธยา",
      ],
      frequency: "",
      finance: "",
      remark: "",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 9,
    work_type: "งานประจำ",
    frequency: "",
    truck_type: "รถเทรลเลอร์/พ่วงดั้มพ์",
    sub_truck_type: "งานขนหิน",
    product: "หิน",
    weight: "",
    origin: ["ห้วยโป่ง ระยอง", "ห้วยกะปิ ชลบุรี"],
    destination: ["ท่าเรือมาบตาพุด", "ท่าเรือแหลมฉบัง เฟส 3"],
    distance: "",
    price: "",
    detail: {
      work_type: "งานประจำ",
      product: "หิน",
      distance: "",
      work: [""],
      truck: [""],
      driver: [""],
      truck_type: "รถเทรลเลอร์/พ่วงดั้มพ์",
      origin: ["ห้วยโป่ง ระยอง", "ห้วยกะปิ ชลบุรี"],
      destination: ["ท่าเรือมาบตาพุด", "ท่าเรือแหลมฉบัง เฟส 3"],
      frequency: "",
      finance: "",
      remark: "",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 8,
    work_type: "งานรายเที่ยว",
    frequency: "5-10 คัน/วัน",
    truck_type: "รถ 10 ล้อ ตู้เปิดข้าง",
    sub_truck_type: "รวมแรงงานขนลงสินค้า",
    product: "อุปโภค-บริโภค",
    weight: "12 ตัน",
    origin: "บางปะกง",
    destination: "กรุงเทพฯ สมุทรปราการ และภาคตะวันออก",
    distance: "ขึ้นอยู่กับงานในแต่ละวัน โซนกทม.ปริมณฑล และภาคตะวันออก",
    price: "",
    detail: {
      work_type: "งานรายเที่ยว",
      product: "อุปโภค-บริโภค",
      distance: "ขึ้นอยู่กับงานในแต่ละวัน โซนกทม.ปริมณฑล และภาคตะวันออก",
      work: ["1.รถต้องเข้าไปลงคิวรับสินค้าในแต่ละวัน งานจะสลับใกล้-ไกล"],
      truck: [
        "1.รถ 10 ล้อตู้เปิดขช้าง ถ้ามีลิฟท์ท้ายจะทำให้การลงสินค้าสะดวกยิ่งขึ้น",
      ],
      driver: ["1.สวมใส่ PPE เสื้อสะท้อนแสง รองเท้าเซฟตี้ ไม้หมอนรองล้อ"],
      truck_type: "รถ 10 ล้อ ตู้เปิดข้าง",
      origin: "บางปะกง",
      destination: "กรุงเทพฯ สมุทรปราการ และภาคตะวันออก",
      frequency: "5-10 คัน/วัน",
      finance:
        "เอกสารนำกลับตัวจริงถึงบริษัทก่อน 17.00 น.โอนเงินภายใน 24 ชั่วโมง ขอแอดวานซ์ได้ 35% ของค่าขนส่ง",
      remark: "ต้องการรับงานกรุณาแจ้งล่วงหน้า 1-2 วัน",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 7,
    work_type: "งานรายเที่ยว",
    frequency: "5-10 คัน/วัน",
    truck_type: "รถเทรลเลอร์พื้นเรียบ",
    product: "ยางแผ่น",
    weight: "30 ตัน",
    origin: "รับตู้คอนเทนเนอร์เปล่าที่แหลมฉบัง ชลบุรี",
    destination:
      "นำตู้คอนเทนเนอร์ไปบรรจุสินค้าที่อ.ปะโคนชัย จ.บุรีรัมย์ แล้วนำกลับแหลมฉบัง ชลบุรี",
    distance: " 752 กม.",
    price: "",
    detail: {
      work_type: "งานรายเที่ยว",
      product: "ยางแผ่น",
      distance: " 752 กม.",
      work: ["1.มีงานทุกวัน จันทร์ - เสาร์"],
      truck: ["1.หางบรรทุกสามารถล็อคตู้คอนเทนเนอร์ได้"],
      driver: ["1.สวมใส่ PPE เสื้อสะท้อนแสง รองเท้าเซฟตี้ ไม้หมอนรองล้อ"],
      truck_type: "รถเทรลเลอร์พื้นเรียบ",
      origin: "รับตู้คอนเทนเนอร์เปล่าที่แหลมฉบัง ชลบุรี",
      destination:
        "นำตู้คอนเทนเนอร์ไปบรรจุสินค้าที่อ.ปะโคนชัย จ.บุรีรัมย์ แล้วนำกลับแหลมฉบัง ชลบุรี",
      frequency: "5-10 คัน/วัน",
      finance:
        "เอกสารนำกลับตัวจริงถึงบริษัทก่อน 17.00 น.โอนเงินภายใน 24 ชั่วโมง ขอแอดวานซ์ได้ 35% ของค่าขนส่ง",
      remark: "ต้องการรับงานกรุณาแจ้งล่วงหน้า 1-2 วัน",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 6,
    work_type: "งานรายเที่ยว",
    frequency: "4 คัน/วัน",
    truck_type: "รถ 10 ล้อ ตู้พื้นเรียบ",
    sub_truck_type: "รวมขนลงสินค้า",
    product: "ปูนถุง",
    weight: "12 ตัน",
    origin: "อ.เมือง จ.ปทุมธานี",
    destination: "กรุงเพทฯ และปริมณฑล",
    distance: "ขึ้นอยู่กับงานในแต่ละวัน",
    price: "",
    detail: {
      work_type: "งานรายเที่ยว",
      product: "ปูนถุง",
      distance: "ขึ้นอยู่กับงานในแต่ละวัน",
      work: ["1.จุดลงสินค้าไม่เกิน 3 จุด/เที่ยว"],
      truck: ["1.มีสายรัดสินค้า ผ้าใบคลุมสินค้า"],
      driver: ["1.สวมใส่ PPE เสื้อสะท้อนแสง รองเท้าเซฟตี้ ไม้หมอนรองล้อ"],
      truck_type: "รถ 10 ล้อ ตู้พื้นเรียบ",
      origin: "อ.เมือง จ.ปทุมธานี",
      destination: "กรุงเพทฯ และปริมณฑล",
      frequency: "4 คัน/วัน",
      finance:
        "เอกสารนำกลับตัวจริงถึงบริษัทก่อน 17.00 น.โอนเงินภายใน 24 ชั่วโมง ขอแอดวานซ์ได้ 35% ของค่าขนส่ง",
      remark: "ต้องการรับงานกรุณาแจ้งล่วงหน้า 1-2 วัน",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 5,
    work_type: "งานรายเที่ยว",
    frequency: "อาทิตย์ละ 10 คัน",
    truck_type: "เทรลเลอร์ พื้นเรียบ",
    product: "เหล็กกล่อง",
    weight: "32 ตัน",
    origin: "บางสะพาน ประจวบคีรีขันธ์",
    destination: "ภาคใต้,ภาคตะวันออก",
    distance: "300-800 กม.",
    price: "9,000-20,000 บาท/เที่ยว",
    detail: {
      work_type: "งานรายเที่ยว",
      product: "เหล็กกล่อง",
      distance: "300-800 กม.",
      work: ["1.เข้ารับสินค้าก่อน 15.00 น. เนื่องจากมีติดเวลา"],
      truck: [
        "1.รถมีเหล็กเสียบข้าง 2 เมตร อย่างน้อย 8 ท่อน",
        "2.ไม้หน้าสามรองเหล็กยาว 2.5 เมตรจำนวน 32 ท่อน",
        " 3.แผ่นยางคลุมหัวเสาเสียบข้าง",
        "4.สายรัด,โซ่",
        "5.ผ้าใบคลุมสินค้า",
      ],
      driver: ["1.แต่งกายสุภาพกางเกงขายาว รองเท้าหุ้มส้น"],
      truck_type: "เทรลเลอร์ พื้นเรียบ",
      origin: "บางสะพาน ประจวบคีรีขันธ์",
      destination: "ภาคใต้,ภาคตะวันออก",
      frequency: "อาทิตย์ละ 10 คัน",
      finance:
        "เอกสารนำกลับตัวจริงถึงบริษัทก่อน 17.00 น.โอนเงินภายใน 24 ชั่วโมง ขอแอดวานซ์ได้ 35% ของค่าขนส่ง",
      remark: "ต้องการรับงานกรุณาแจ้งล่วงหน้า 1-2 วัน",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 4,
    work_type: "งานรายเที่ยว",
    frequency: "อาทิตย์ละ 10 คัน",
    truck_type: "เทรลเลอร์ พื้นเรียบ",
    product: "แผ่นยิปซั่ม",
    weight: "32 ตัน",
    origin: "หนองบัว นครสวรรค์",
    destination: "ภาคเหนือ,ภาคอีสาน,กรุงเทพและปริมณฑล",
    distance: "200-800 กม.",
    price: "7,000-15,000 บาท/เที่ยว",
    detail: {
      work_type: "งานรายเที่ยว",
      product: "แผ่นยิปซั่ม",
      distance: "200-800 กม.",
      work: [
        "1.สินค้าขึ้นลงเป็นพาเลท",
        "2.เนื่องจากสินค้าค่อนข้างมีความเปราะจึงต้องระมัดระวังในการรัดสายไม่ให้แน่นหรือหลวมเกินไป",
        " 3.ต้นทางขึ้นสินค้าได้ 24 ชั่วโมง",
      ],
      truck: ["1.รถมีสายรัด", "2.ผ้าใบคลุมสินค้า", "3.ประกับรองสายรัด"],
      driver: ["1.แต่งกายสุภาพกางเกงขายาว รองเท้าหุ้มส้น"],
      truck_type: "เทรลเลอร์ พื้นเรียบ",
      origin: "หนองบัว นครสวรรค์",
      destination: "ภาคเหนือ,ภาคอีสาน,กรุงเทพและปริมณฑล",
      frequency: "อาทิตย์ละ 10 คัน",
      finance:
        "เอกสารนำกลับตัวจริงถึงบริษัทก่อน 17.00 น.โอนเงินภายใน 24 ชั่วโมง ขอแอดวานซ์ได้ 35% ของค่าขนส่ง",
      remark: "ต้องการรับงานกรุณาแจ้งล่วงหน้า 1-2 วัน",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 3,
    work_type: "งานรายเที่ยว",
    frequency: "อาทิตย์ละ 3 คัน",
    truck_type: "เทรลเลอร์ พื้นเรียบ",
    product: "บรรทุกขวดเปล่าบรรจุลัง",
    weight: "32 ตัน",
    origin: "เมือง สมุทรปราการ",
    destination: "บางบาล พระนครศรีอยุธยา",
    distance: "120 กม.",
    price: "4000 บาท/เที่ยว",
    detail: {
      work_type: "งานรายเที่ยว",
      product: "บรรทุกขวดเปล่าบรรจุลัง",
      distance: "120 กม.",
      work: [
        "1.บรรทุกขวดเปล่าบรรจุลังเบียร์สูง 8 ลัง บรรทุก 24 พาเลท (1*1.2 เมตร)",
        "2.สินค้าขึ้นลงพาเลท",
        "3.คนรถแสกน QR-code หน้าโรงงานต้นทางปลายทางทุกครั้ง",
      ],
      truck: ["1.รถมีสายรัด", "2.ผ้าใบคลุมสินค้า"],
      driver: ["1.แต่งกายสุภาพกางเกงขายาว รองเท้าหุ้มส้น"],
      truck_type: "เทรลเลอร์ พื้นเรียบ",
      origin: "เมือง สมุทรปราการ",
      destination: "บางบาล พระนครศรีอยุธยา",
      frequency: "อาทิตย์ละ 3 คัน",
      finance:
        "เอกสารนำกลับตัวจริงถึงบริษัทก่อน 17.00 น.โอนเงินภายใน 24 ชั่วโมง ขอแอดวานซ์ได้ 35% ของค่าขนส่ง",
      remark: "ต้องการรับงานกรุณาแจ้งล่วงหน้า 1-2 วัน",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 2,
    work_type: "งานประจำ",
    frequency: "วันละ 5 คัน",
    truck_type: "เทรลเลอร์ ตู้ผ้าใบเปิดข้าง",
    product: "น้ำยาปรับผ้านุ่ม",
    weight: "30 ตัน",
    origin: "ลำลูกกาคลอง 13 ปทุมธานี",
    destination: "ปริมณฑล และ ภาคกลาง",
    distance: "100 กม.",
    price: "3,600-5,500 บาท/เที่ยว",
    detail: {
      work_type: "งานประจำ",
      product: "น้ำยาปรับผ้านุ่ม",
      distance: "100 กม.",
      work: ["1.บรรทุกสินค้า 26 พาเลท (พาเลท 1.2*1 เมตร)"],
      truck: ["1.อุปกรณ์หมอนรองล้อ"],
      driver: [
        "1.เสื้อสะท้อนแสง หมวกเซฟตี้",
        "2.แต่งกายสุภาพกางเกงขายาว รองเท้าหุ้มส้น",
      ],
      truck_type: "เทรลเลอร์ ตู้ผ้าใบเปิดข้าง",
      origin: "ลำลูกกาคลอง 13 ปทุมธานี",
      destination: "ปริมณฑล และ ภาคกลาง",
      frequency: "วันละ 5 คัน",
      finance: "",
      remark: "",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
  {
    id: 1,
    work_type: "งานรายเที่ยว",
    frequency: "เดือนละ 5 คัน",
    truck_type: "เทรลเลอร์ พื้นเรียบ",
    product: "ไม้อัด",
    weight: "30 ตัน",
    origin: "ท่านาแร้ง ข้ามด่าน หนองคาย",
    destination: "แหลมฉบัง ชลบุรี",
    distance: "680 กม.",
    price: "17,500 บาท/เที่ยว",
    detail: {
      work_type: "งานรายเที่ยว",
      product: "ไม้อัด",
      distance: "680 กม.",
      work: ["1.ก่อนวิ่งงานส่งเอกสารล่วงหน้า 2 วัน"],
      truck: [
        "1.รถมีตัวที",
        "2.รถมีสายรัด,โซ่",
        "3.เหล็กเสียบข้างอย่างน้อย 8 ท่อน",
        "4.อุปกรณ์หมอนรองล้อ",
        "5.ผ้าใบคลุมสินค้า",
      ],
      truck_type: "เทรลเลอร์ พื้นเรียบ",
      origin: "ท่านาแร้ง ข้ามด่าน หนองคาย ",
      destination: "แหลมฉบัง ชลบุรี",
      frequency: "เดือนละ 5 คัน",
      driver: [
        "1.พาสปอร์ต",
        "2.เสื้อสะท้อนแสง หมวกเซฟตี้",
        "3.แต่งกายสุภาพกางเกงขายาว รองเท้าหุ้มส้น",
      ],
      finance: "",
      remark: "",
      line: "https://lin.ee/G9URMOgj",
      tel: "0808357955",
    },
  },
];
